/* src/fonts.css */
@font-face {
  font-family: 'Gontserrat';
  src: url('./fonts/Gontserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gontserrat';
  src: url('./fonts/Gontserrat-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}