#dropdown{
    padding:5px;
}

#dropdown
{
    background: rgba(0, 0, 0, 0);
    outline: none;
    color:white;
    box-shadow: none;
    outline:none;
    border:none;
}

#dropdown-mobile
{
    background: rgba(0, 0, 0, 0);
    outline: none;
    color:black;
    box-shadow: none;
    outline:none;
    border:none;
}

option{
    color:black;
    background:white;
    box-shadow: none;
    outline:none;
    border:none;
}