.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top:50px;
  padding-bottom:50px;
}

/*BACKBUTTON*/
.back-button{
  position:fixed;
  top:70px;
  left:30px;
  width:24px;
  transition: 0.2s ease;
  cursor:pointer;
}

.back-button:hover{
  transform:scale(1.2);
}

@media (max-width: 768px) {
  .back-button{
    visibility:hidden;
  }
  .balance{
    visibility: hidden;
  }
}


.balance{
  position:fixed;
  top:60px;
  right:10px;
  padding:15px;
  background-color:white;
  color:#00A3FF;
  border: 1px solid #00A3FF;
  border-radius: 10px;
  font-size:12px;
  font-weight: 500;
  border-radius:10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* NAVBAR */

.navbar{
  position:fixed;
  top:0;
  width:100%;
  background-color: #DD3333;
  color:white;
  height:50px;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index:100;
}

.brand{
  margin-left:20px;
  font-size:24px;
  font-weight:700;
  cursor:pointer;
  font-family: 'Gontserrat', sans-serif;
  font-weight: 500;
}

.brand-eos{
  color:black;
  font-family: 'Gontserrat', sans-serif;
  font-weight: 700;
}

.navbar-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.temporary-drawer{
  justify-self: flex-end;
  margin-right:10px;
}

.temporary-drawer-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:70vw;
  margin-top:50px;
  gap:30px;
}



.temporary-drawer-button{
  background-color:white;
  border:none;
  font-weight: 500;
}

.temporary-drawer-button-log{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.logbutton {
  border: none;
  color: white;
  background-color: #DD3333;
  padding: 10px 20px 10px 20px;
  cursor:pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.username {
  border: none;
  color: white;
  background-color: #DD3333;
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.usertype{
  font-size:11px;
}

.menubutton {
  border: none;
  color: white;
  padding: 10px 20px 10px 20px;
  cursor:pointer;
  background-color: transparent;
}

/*ACCORDION*/

.accordion-text{
  font-size:14px;
  color:gray;
  margin-left:10px;
  width:100%;
}

.paragraph {
  margin-bottom: 10px;
}

.Collapsible{
  width:100%
}


/*FEED MENU*/
.feedmenu {
  width:50vw;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-top:50px;
}
.feedmenu-category{
  color:#939393;
  display: flex;
  align-items: center;
  justify-content: center;
  height:40px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  transition: 0.2s ease-out, color 0.2s ease-out;
  cursor: pointer;
  font-size:14px;
}


.feedmenu-category-selected{
  color:white;
  display: flex;
  align-items: center;
  justify-content: center;
  height:40px;
  background: #DD3333;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  transition: 0.2s ease-out, color 0.2s ease-out;
  font-size:14px;
}

.button-2{
  color:#939393;
  display: flex;
  align-items: center;
  justify-content: center;
  height:40px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  transition: 0.2s ease-out, color 0.2s ease-out;
  cursor: pointer;
  font-size:14px;
  text-align: center;
  position: relative;
  transition: all .35s;
  text-decoration: none;
}

.button-2 span{
  position: relative;
  z-index: 2;
}

.button-2:after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-radius:5px;
  background: #DD3333;
  transition: all .35s;
}

.button-2:hover{
  color: #fff;
}

.button-2:hover:after{
  width: 100%;
}

/*FILE UPLOAD*/
.file-upload{
  display:flex;
  justify-content: flex-start;
  width:100%;
}

.file-upload-button{
    color: #00A3FF;
    border:1px solid #00A3FF;
    border-radius: 10px;
    padding:10px;
    background-color: white;
    cursor: pointer;
}

.dropzone {
  border: 1px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  width:100%;
  box-sizing: border-box;
  height:100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone.drag-active {
  border-color: #007bff;
  background-color: #f3f3f3;
}

.upload-logo-button{
  color: #00A3FF;
  border:1px solid #00A3FF;
  border-radius: 10px;
  padding:10px;
  background-color: white;
  cursor: pointer;
  align-self:flex-end;
  width:100%;
}

.upload-logo-area{
  display: flex;
  width:100%;
  gap:10px;
  flex-direction: column;
}
/*COMMENT*/
.comment{
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  background-color: white;
  width:50vw;
  margin-top:20px;
  padding:20px;
  padding-bottom:50px;
  box-sizing: border-box;
  border-radius: 10px;
  border:none;
  font-family: inherit;
  resize: none;
  position:relative;
}

.delete-comment{
  position: absolute;
  bottom:20px;
  right:20px;
  font-size:12px;
  cursor: pointer;
}

.comment-time{
  position: absolute;
  top:20px;
  right:20px;
}

/*SUBMITCOMMENT*/
.submit-comment{
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  background-color: white;
  width:50vw;
  margin-top:20px;
  padding:20px;
  padding-bottom:50px;
  box-sizing: border-box;
  border-radius: 10px;
  border:none;
  font-family: inherit;
  resize: none;
}



.accordion{
  width:50vw;
}

.submit-comment:focus{
  outline:none;
}

.submit-comment-button{
  color: white;
  background-color: #00A3FF;
  border:1px solid #00A3FF;
  border-radius: 10px;
  padding:10px;
  cursor: pointer;
}

/*FEED CARD*/

/* SixStarRating.module.css */
.starBox {
  display: inline-block;
  font-size: 26px;
  margin-right: 2px;
  color: #bbbac0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.1s ease

}

.starBox::before {
  content: "★";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--fill, 0%);
  color: #DD3333;
  overflow: hidden;
  white-space: nowrap;
  transition: width 0.05s ease; /* Add this line */
}


.starBox:hover{
  transform:scale(1.3)
}



.card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 30px;
  width: 50vw;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  margin-top: 20px;
  box-sizing: border-box;
  position:relative;
  z-index:5;
  overflow:hidden;
}

.collaborationsview{
  width:50vw;
}

@media (min-width: 768px) {
  .temporary-drawer{
    display:none;
  }
}

@media (max-width: 768px) {
  .navbar-buttons{
    display:none;
  }
  .comment{
    width:90vw;
  }
  .submit-comment{
    width:90vw;
  }
  .accordion{
    width:90vw;
  }
  .card {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
  .menubutton{
    color:black;
    padding: 0px;
  }
}

.card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-logo-name-category {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-logo {
  width: auto;
  max-width:60px;
  max-height: 60px;
  margin-right: 20px;
}

.card-name-category {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-name {
  font-size: 18px;
  color: #3A3A3A;
  white-space: wrap;
}

.card-category {
  color: #BABABA;
  font-size: 14px;
}

.card-buttons {
  display: flex;
  flex-direction: column;
}

.card-rank {
  margin-bottom: 5px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.16));
  border: none;
  color: #909090;
  background-color: white;
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  width: 80px;
  align-self: flex-end;
}

.card-source-outline {
  margin-bottom: 5px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.12));
  border: none;
  color: #DD3333;
  background-color: white;
  border: 1px solid #DD3333;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.card-source {
  margin-bottom: 5px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.16));
  border: none;
  color: white;
  background-color: #DD3333;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  cursor:pointer;
}

.card-source-blue{
  color: white;
  background-color: #00A3FF;
  border:1px solid #00A3FF;
  border-radius: 10px;
  padding:10px;
}

.open-github {
  margin-bottom: 5px;
  border: none;
  color: white;
  border:1px solid #31BD00;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  cursor:pointer;
  background-color: #2DBA4E;
  width:100%;
}

.firsttime-choice-button {
  margin-bottom: 5px;
  border: none;
  color: white;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  cursor:pointer;
  width:100%;
}

.firsttime-choice-button-wrapper{
  width:100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap:10px;
}

.card-button {
  margin-bottom: 5px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.16));
  border: none;
  color: white;
  background-color: #DD3333;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
}

.card-button-description {
  margin-bottom: 5px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.16));
  border: none;
  color: white;
  border: solid 1px #DD3333;
  background-color: #DD3333;
  color: white;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  cursor:pointer;
}

.card-button-solution {
  margin-bottom: 5px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.16));
  border: none;
  color: white;
  background-color: #DD3333;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  cursor:pointer;
}

.card-star-ratings {
  margin-top: 20px;
}


.star-rating{
  transition:0.1s;
  cursor:pointer;
  width:25px;
}

.star-rating:hover{
  transform:scale(1.2)
}

.card-description {
  margin-top: 20px;
  color: #909090;
}

.card-bottom {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 10px;
  flex-wrap: wrap;
}

.social{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:5px;
  wrap:nowrap;
}

.social-text{
  text-decoration: none;
  color:#575757;
}

.reward{
  font-size:11px;
  color:#00A3FF;
}

@media (max-width: 768px) {
  .card-source-outline{
    padding: 5px 5px 5px 5px;
    font-size:12px;
    max-width:100px;
    margin-left:10px;
  }
  .card-source{
    padding: 10px 10px 10px 10px;
    font-size:12px

  }
  .feedmenu{
    width:90vw;
    grid-template-columns: repeat(3, 1fr);
  }
  .card {
    padding: 20px;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }

  .collaborationsview{
    width:90vw;
  }

  .card-logo {
    margin-right: 10px;
  }

  .card-name {
    font-size: 16px;
  }

  .card-category {
    font-size: 12px;
  }

  .card-star-ratings {
    margin-top: 10px;
  }

  .card-description {
    margin-top: 10px;
  }

  .card-bottom {
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }

  .card-button,
  .card-button-description,
  .card-button-solution {
    padding: 8px 20px 8px 20px;
  }
}

/*ADDSOLUTION*/

.addsolutionvalue{
  align-self:flex-end;
  background:#DD3333;
  border:none;
  border-radius: 10px;
  padding:5px 10px;
  color:white;
  margin-top:-20px;
}

.addsolution{
  width:50vw;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:15px;
  margin-top:30px;
  flex-direction: column;
  align-items: flex-start;
}

.addsolution-input-field{
  width:100%;
  padding:10px;
  border:1px solid gray;
  border-radius:10px;
}

.addsolution-input-field:focus{
  outline: none;
}

.addsolution-input-descriptor{
  align-self:flex-start;
  font-size:12px;
  font-weight: 500;
}

.solution-splitter{
  border: 1px dashed #eaeaea;
  width:100%;
  margin-top:20px;
}

@media (max-width: 768px) {
  .addsolution{
    width:80vw;
  }
}



/*MODALS*/
.modal-input-field{
  width:100%;
  padding:10px;
  border:1px solid gray;
  border-radius:10px;
}

.modal-input-field:focus{
  outline:none;
}

.modal-submit{
  width:100%;
  background-color:#DD3333;
  color:white;
  font-weight:600;
  border-radius:10px;
  border:none;
  height:50px;
  padding:12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap:10px;
  text-decoration: none;
  cursor:pointer;
}

/*VERIFIED USERS*/
.verified-users{
  min-height:80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap:10px;
  font-size:14px;
  margin-top:60px;
}

.verified-users-user{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  font-size:14px;
  width:80vw;
}

/*VERIFYUSERS*/
.verifyusers-area{
  display: flex;
  flex-direction: column;
  gap:10px;
  height:80vh;
  align-items: center;
  justify-content: center;
}

.verifyusers-user{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap:10px;
}

.verifyuser-button{
  border: none;
  color: white;
  background-color: #DD3333;
  padding: 10px 15px 10px 15px;
  cursor:pointer;
  border-radius: 5px;
  margin-left:10px;
}

.loader{
  position:absolute;
  left:50vw;
  top:50vw;
  color:black;
  font-size:50px
}


.share{
  position:absolute;
  bottom:-32px;
  right:20px;
  background: #fff;
  color:#00A3FF;
  border:1px solid #00A3FF;
  height:50px;
  border-radius:5px 5px 0px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index:4;
  font-size:12px;
  padding-left:5px;
  padding-right:5px;
  font-weight:600;
  transition:0.2s ease-in-out;
  text-align:center;
  cursor:pointer;
}

.share:hover{
  bottom:-15px;
  color:white;
  background: #00A3FF;

}


.website-button{
  display:block;
  width:100%;
}


.concept-image{
  position:absolute;
  width:100px;
  top:15px;
  right:15px
}

@media (max-width: 768px) {
  .concept-image{
    visibility:hidden;
  }
}

.quizarea{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  gap:10px;
  width:100%;
}

.quizansweroption{
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  justify-content: center;
  gap:10px;
  color:rgb(58, 58, 58);
}

.quizbutton{
  width:100%;
  background-color:#DD3333;
  color:white;
  border-radius:20px;
  padding:10px;
  box-sizing: border-box;
  border:none;
  margin-top:20px;
  cursor:pointer;
}

.quizbuttonsmall{
  align-self:flex-end;
  background:#DD3333;
  border:none;
  border-radius: 10px;
  padding:5px 10px;
  color:white;
}

.quizbuttons{
  display: flex;
  flex-direction: row;
  gap:10px;
  width:100%;
}